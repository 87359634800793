@tailwind base;
@tailwind components;
@tailwind utilities;

@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
	@tailwind base;
}

@layer tailwind-utilities {
	@tailwind components;
	@tailwind utilities;
}

@layer base {
	a {
		@apply text-gardian-linkblue
	}
	body {
		font-family: Montserrat, system-ui, sans-serif;
	}
	html,body {
		height: 100%;
	}
}